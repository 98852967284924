

body {
  font-family: Montserrat, sans-serif;
  /* font-size: 1.822917rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  text-decoration: none; */
}
/* .col {
  min-height: 100vh;
  position: relative;
  background: url(./../../../public/images/pattern_fx.jpg) no-repeat center 0;
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */
.wrapper-6 {
  /* height: 1991px; */
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.bg-photo {
  background: url('../../../public/images/bg_photo.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  height:50vh;
/* 
 background-image: url("../../../public/images/bg_photo.png");
 background-size: cover;
 background-repeat: no-repeat; */
}
.layer_6 {
  /* left: 50%; */
  /* position: absolute; */
  top: 0;
  background-image: url("../../../public/images/layer_6_copy_2.jpg");
  height:100vh;
  z-index: -3;
  /* margin-left: -1424px; */
}

.mt-15 {
  margin-top: 15% !important;
}


.ellipse {
  max-width: 30%;
  position: absolute;
  top: 0;
  height: 50vh;
}
.main {
  /* height: 100%;  */
  /* left: 50%; */
  position: absolute;
  top: 50px;
  /* width: 100%; */
  /* margin-left: -1429px; */
}

.text_area {
  position: absolute;
  left: 50%;
}

.button-box {
  height: 15px;
  left: 50%;
  /* position: absolute; */
  top: 117px;
  /* width: 5%; */
  -webkit-border-radius: 19.5px;
  -moz-border-radius: 19.5px;
  border-radius: 19.5px;
  background: #48007b;
  /* margin-left: 972.5px; */
}
.zapisatsya {
  margin-top: 15%;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.2em;
  text-align: left;
  border-radius: 19.5px;
  background: #48007b;
  padding: 10px;
  cursor: pointer;
  padding: 10px 30px 10px 30px;
  bottom: 5%;
  position: fixed;
}
.ray {
  /* left: 50%; */
  /* position: absolute; */
  display: table;
  top: 343px;
  width: 40%;
  /* margin-left: 1225.5px; */
  /* background-image: url("../../../public/images/ray.png"); */
}
.ray-2 {
  /* left: 50%; */
  /* position: absolute; */
  display: table;
  top: 548px;
  width: 40%;
  /* background-image: url("../../../public/images/ray.png"); */
  /* margin-left: -70.5px; */
}
.arrow {
  position: absolute;
  right: 5%;
  bottom: 5%;
  width: 10%;
  max-width: 70px;
}

.menu {
  /* left: 30%; */
  position: absolute;
  top: 33px;
  width: 100%;
  font-size: 1rem;
  /* margin-left: -396.5px; */
}
.nav {
  position: relative;
}
.nav-list {
  position: relative;
  list-style: none;
}
.nav-list li {
  float: left;
  margin: 0 70.666664px 0 0;
}
.nav-list li:last-child {
  margin-right: 0;
}
.nav-item-1 {
  color: #48007b;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.nav-item-1-2 {
  color: #48007b;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.nav-item-1-3 {
  color: #48007b;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.nav-item-1-4 {
  color: #48007b;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.nav-item-1-5 {
  color: #48007b;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.nav-item-1-6 {
  color: #48007b;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.nav-item-1-7 {
  color: #48007b;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.logo_area {
  border-bottom: 6px solid #d31a71;
  margin: 20px 0 20px 0 !important;
  width: 40%;
}

.logo_dm_white_header {
  position: absolute;
  max-width: 50%;
  max-width: 250px;
  width: 20%;
  z-index: 2;
}

.logo_dm_white_menu {
  position: absolute;
  bottom: 10%;
  width: 60%;
  z-index: 2;
}

.body_text {
  /* left: 50%; */
  /* position: absolute; */
  top: 970px;
  width: 80%;
  color: hwb(0 43% 56%);
  font-size: 1em; /* 33.33px / 16px = 2.083125rem */
  line-height: 1.2;
}
.text {
  /* left: 50%; */
  /* position: absolute; */
  top: 886px;
  /* width: 90%; */
  color: #d31a71;
  font-size: 1.5em; /* 58.33px / 16px = 3.645625rem */
  font-weight: 900;
  line-height: 67px;
  text-transform: uppercase;
}
.body-text-copy {
  /* left: 50%; */
  /* position: absolute; */
  top: 1408px;
  width: 80%;
  color: #535252;
  font-size: 1em; /* 33.33px / 16px = 2.083125rem */
  line-height: 1.2;
}

.kruche {
  /* left: 50%; */
  /* position: absolute; */
  /* top: 509px; */
  width: 85%;
  display: table;
}
.tancui {
  /* left: 50%; */
  /* position: absolute; */
  top: 325px;
  width: 70%;
  display: table;
}
.bloki {
  margin: -11px 0 0;
  position: relative;
}
.tancevalnyi-centr {
  height: 725px;
  overflow: hidden;
  position: relative;
  width: 100%; /* 3556px / 3556px = 100% */
}
.rectangle-3 {
  left: 50%;
  /* position: absolute; */
  top: 0;
}
.shadow {
  left: 0;
  /* position: absolute; */
  top: 630px;
  width: 100%; /* 3556px / 3556px = 100% */
}
.bychkova {
  left: 50%;
  /* position: absolute; */
  top: 0;
}

.about_us_item {
  position: static;
  top: 20%;
}

.text-box {
  left: 50%;
  /* position: absolute; */
  top: 392px;
}
.body-text-copy-2 {
  left: 50%;
  /* position: absolute; */
  top: 244px;
  width: 1375px;
  color: #ffffff;
  line-height: 1.2;
}
.text-3 {
  left: 50%;
  position: absolute;
  top: 73px;
  color: #ffffff;
  font-size: 3.645833rem; /* 58.33px / 16px = 3.645625rem */
  font-weight: 900;
  line-height: 1.2;
  text-transform: uppercase;
  margin-left: -472px;
}
.text-4 {
  left: 50%;
  position: absolute;
  top: 336px;
  color: #ffffff;
  font-size: 6.89293rem; /* 110.29px / 16px = 6.893125rem */
  font-weight: 900;
  line-height: 132px;
  text-transform: uppercase;
  margin-left: 674px;
}

.wrapper-9 {
  height: 2085px;
  overflow: hidden;
  position: relative;
  width: 100%; /* 3556px / 3556px = 100% */
}
.text-5 {
  left: 50%;
  position: absolute;
  top: 164px;
  margin-left: -318px;
}
.pochemu_my {
  left: 0;
  padding: 50px 20px 50px 20px;
  /* position: absolute; */
  top: 30px;
  width: 100%; /* 3556px / 3556px = 100% */
  background: url(./../../../public/images/layer_6_copy_2.jpg) no-repeat center 0;
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  

    /* left: 50%; */
    /* position: absolute; */
    /* top: 0;
    background-image: url("../../../public/images/layer_6_copy_2.jpg");
    height:100vh;
    z-index: -3; */
    /* margin-left: -1424px; */
}
.styles {
  left: 0;
  padding: 50px 20px 50px 20px;
  /* position: absolute; */
  top: 30px;
  width: 100%; /* 3556px / 3556px = 100% */
  /* background: url(./../../../public/images/mqnddq3pl5a.jpg) no-repeat center 0; */
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;


    /* left: 50%; */
    /* position: absolute; */
    /* top: 0;
    background-image: url("../../../public/images/layer_6_copy_2.jpg");
    height:100vh;
    z-index: -3; */
    /* margin-left: -1424px; */
}
.l-constrained-2 {
  margin: 0 auto;
  padding: 0 395px;
  position: relative;
  width: 3556px;
}
.col-3 {
  float: left;
  margin: 324px 113px 0 0;
  position: relative;
  width: 783px;
}

.about_us_text {
  left: 13px;
  top: 15%;
  color: hwb(0 43% 56%);
  font-size: 1rem;
  line-height: 1.2;
  text-align: left;
  max-width: 400px;
  margin: auto;
}

.social_media
{
  padding-left: 10%;
}

.text_6 {
  color: #d31a71;
  top: 30%;
  font-size: 2rem; /* 58.33px / 16px = 3.645625rem */
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}
.text_7 {
  left: 13px;
  top: 15%;
  /* margin: 5px auto 0; */
  /* position: relative; */
  /* width: 727px; */
  color: hwb(0 43% 56%);
  font-size: 1rem; /* 33.33px / 16px = 2.083125rem */
  line-height: 1.2;
  text-align: center;
}
.style_description {
  left: 13px;
  top: 15%;
  /* margin: 5px auto 0; */
  /* position: relative; */
  /* width: 727px; */
  color: hwb(0 43% 56%);
  font-size: 1rem; /* 33.33px / 16px = 2.083125rem */
  line-height: 1.2;
  text-align: left;
  padding: 10px 5px 0 5px;
}
.adekvatnye-ceny {
  left: 11.5px;
  margin: 177px auto 0;
  position: relative;
  width: 760px;
}
.text-8 {
  color: #d31a71;
  font-size: 3.645833rem; /* 58.33px / 16px = 3.645625rem */
  font-weight: 900;
  text-align: right;
  text-transform: uppercase;
}
.text-9 {
  margin: 2px auto 0;
  width: 747px;
  color: #4d4c4c;
  font-size: 2.083333rem; /* 33.33px / 16px = 2.083125rem */
  line-height: 1.2;
  text-align: right;
}
.col-34 {
  float: left;
  position: relative;
  width: 1721px;
}
.rectangle-4 {
  height: 18px;
  margin: 0 0 0 59px;
  position: relative;
  width: 861px;

  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.row-25 {
  margin: 105px 0 0;
  position: relative;
}
.tantsutsykruche {
  /* float: left; */
  /* margin: 0 auto;
  width: 75%; */
  width: 70%;
  top:10%;
  margin-left: 15%;
  margin-right: 15%;
  position: static;
}
.col-38 {
  float: left;
  margin: 175px 0 0;
  position: relative;
  width: 762px;
}
.rectangle-7-holder {
  left: 0;
  position: absolute;
  top: 1543px;
  width: 100%; /* 3556px / 3556px = 100% */
  border-top: 1px solid #0d0f05; /*stroke*/
  border-bottom: 1px solid #0d0f05; /*stroke*/
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.cap {
  display: block;
  left: 1074px;
  margin: -1px -0.028121% 0 auto; /* -1px / 3556px = -0.028121% */
  position: relative;
}
.komfortnoe-prostranstvo {
  height: 545px;
  left: 50%;
  position: absolute;
  top: 1539px;
  width: 3438px;
  margin-left: -1778px;
}
.text-14 {
  left: 50%;
  position: absolute;
  top: 79px;
  color: #ffffff;
  font-size: 3.645833rem; /* 58.33px / 16px = 3.645625rem */
  font-weight: 900;
  line-height: 63px;
  text-transform: uppercase;
  margin-left: 162px;
}
.text-15 {
  left: 50%;
  position: absolute;
  top: 146px;
  width: 836px;
  color: #0a0a0a;
  font-size: 2.083333rem; /* 33.33px / 16px = 2.083125rem */
  line-height: 1.2;
  margin-left: 162px;
}
.text-16 {
  left: 50%;
  position: absolute;
  top: 335px;
  width: 836px;
  color: #ffffff;
  font-size: 2.864583rem; /* 45.83px / 16px = 2.864375rem */
  font-weight: 900;
  line-height: 1.2;
  margin-left: 230px;
}
.layer-holder {
  left: 50%;
  padding: 488px 0 29px;
  position: absolute;
  top: 4px;
  width: 1780px;
  background: url(./../../../public/images/8bxq7-vzdvq.png) no-repeat;
  margin-left: -1719px;
}
.slider {
  margin: 0 714px 0 834px;
  position: relative;
}
.ellipse-3 {
  float: left;
  height: 24px;
  width: 24px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.ellipse-3-copy {
  float: left;
  height: 24px;
  margin: 0 0 0 25px;
  width: 24px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #979797;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.ellipse-3-copy-2 {
  float: left;
  height: 24px;
  margin: 0 0 0 29px;
  width: 24px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #979797;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.ellipse-3-copy-3 {
  float: left;
  height: 24px;
  margin: 0 0 0 29px;
  width: 24px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #979797;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.ellipse-3-copy-4 {
  float: left;
  height: 24px;
  margin: 0 0 0 29px;
  width: 24px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #979797;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.shadow-copy {
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: -1719px;
}
.lights {
  left: 50%;
  position: absolute;
  top: 0;
  width: 3556px;
  opacity: 0.47;
  margin-left: -1778px;
}
.layer-6 {
  float: left;
}
.layer-6-copy {
  float: right;
  margin: 47px 0 0;
}
.text-box-copy {
  left: 50%;
  position: absolute;
  top: 1855px;
  margin-left: 103px;
}
.shadow-copy-2 {
  display: block;
  margin: -1px auto 0;
  position: relative;
  right: 58px;
}
.l_unconstrained {
  /* margin: -119px 0 0; */
  position: relative;
  /* background: url(./../../../public/images/layer_6_copy_2.jpg) no-repeat center 0; */
  background-color: #000000;
  text-align: center;
}
.l_constrained {
  margin: 0 auto;
  position: relative;
  /* width: 3556px; */
}

.underline_margin_check {
  display: inline-block;
  position: relative;
  max-width: 30%;
}

.underline_margin_check:after {
  position: absolute;
  content: '';
  border-bottom: 6px solid #d31a71;
  width: 60%;
  transform: translateX(-50%);
  bottom: -15px;
  left: 50%;
}

.napravleniya {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 2863px;
}
.napravleniya-2 {
  height: 1809px;
  position: relative;
  width: 2863px;
}
.title {
  /* left: 50%;
  position: absolute;
  top: 148px;
  margin-left: -352.5px; */

  /* left: 50%; */
  /* position: absolute; */
  /* top: 148px; */
  /* margin-left: -352.5px; */
  min-width: 300px;
  /* width: 30%; */
  margin: 0 auto;
  margin-top: 10%;
  margin-bottom: 5%;
  font-size: 2.5rem;
  font-weight: 800;
  /* font-family: "cy grotesk grand dark"; */
  color: white;
  text-align: center;
  text-transform: uppercase;
}
.row-7 {
  left: 50%;
  /* min-height: 1336px; */
  padding: 0 15% 0 15%;
  /* position: absolute; */
  top: 0;
  width: 100%;
  /* background: url(./../../../public/images/lights.png) no-repeat; */
  /* background: url(./../../../public/images/mqnddq3pl5a.jpg) no-repeat center 0; */
  /* margin-left: -1410.5px; */
}
.jazz-punk {
  float: left;
  margin: 91px 0 0;
  position: relative;
  /* width: 500px; */
  z-index: 0;
}
.play_copy {
  /* left: 100px; */
  margin-left: 70%;
  /* margin: 0 auto; */
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 228;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.play_copy:hover{
  background: #ae165e;
}

.icon_youtube {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
.rectangle-9-holder {
  margin: -35px 0 0;
  min-height: 50px;
  padding: 0 28px;
  position: relative;
  z-index: 223;
  border: 4px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.styles_title {
  color: #ffffff;
  font-size: 1.4rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  line-height: 50px;
  text-transform: uppercase;

  padding: 0 28px;
  position: relative;
  z-index: 233;
  border: 5px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.styles_youtube_icon {
  margin-left: auto; 
  margin-right: 15px;
  margin-bottom: -25px;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 278;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}

.styles_free_icon_youtube {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.text-18 {
  margin: 17px auto 0;
  position: relative;
  /* width: 490px; */
  z-index: 225;
  font-weight: 300;
  line-height: 1.2;
}
.col-6 {
  float: left;
  margin: 0 0 0 82px;
  position: relative;
  width: 528px;
}
.rectangle-4-2 {
  height: 18px;
  margin: 0 auto;
  width: 528px;

  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.hip-hop {
  margin: 73px auto 0;
  position: relative;
  width: 500px;
  z-index: 0;
}
.play-copy-2 {
  left: 192.5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 238;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.free-icon-youtube-1077046-2 {
  display: block;
  margin: 0 auto;
}
.rectangle-9-holder-2 {
  margin: -35px 0 0;
  min-height: 85px;
  padding: 0 28px;
  position: relative;
  z-index: 233;
  border: 6px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.text-19 > * {
  color: #ffffff;
  font-size: 2.604167rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  line-height: 85px;
  text-transform: uppercase;
}
.text-20 {
  margin: 17px auto 0;
  position: relative;
  width: 490px;
  z-index: 235;
  font-weight: 300;
  line-height: 1.2;
}
.contemp {
  float: left;
  margin: 91px 0 0 77px;
  position: relative;
  width: 500px;
  z-index: 0;
}
.play-copy-3 {
  left: 197.5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 248;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.free-icon-youtube-1077046-3 {
  display: block;
  margin: 0 auto;
}
.rectangle-9-holder-3 {
  margin: -35px 0 0;
  min-height: 85px;
  padding: 0 21px;
  position: relative;
  z-index: 243;
  border: 6px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.contemporary > * {
  color: #ffffff;
  font-size: 2.604167rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  line-height: 85px;
  text-transform: uppercase;
}
.text-21 {
  margin: 15px auto 0;
  position: relative;
  width: 490px;
  z-index: 245;
  font-weight: 300;
  line-height: 1.2;
}
.dancehall {
  left: 50%;
  position: absolute;
  top: 340px;
  width: 500px;
  z-index: 0;
  margin-left: -1431.5px;
}
.play {
  left: 193.5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 218;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.free-icon-youtube-1077046-4 {
  display: block;
  margin: 0 auto;
}
.rectangle-9-holder-4 {
  margin: -35px 0 0;
  min-height: 85px;
  padding: 0 36px;
  position: relative;
  z-index: 213;
  border: 6px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.dancehall-2 > * {
  color: #ffffff;
  font-size: 2.604167rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  line-height: 85px;
  text-transform: uppercase;
}
.text-22 {
  margin: 15px auto 0;
  position: relative;
  width: 490px;
  z-index: 215;
  font-weight: 300;
  line-height: 1.2;
}
.vogue {
  left: 50%;
  position: absolute;
  top: 340px;
  width: 500px;
  z-index: 0;
  margin-left: 931.5px;
}
.play-copy-4 {
  left: 194.5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 258;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.free-icon-youtube-1077046-5 {
  display: block;
  margin: 0 auto;
}
.rectangle-9-holder-5 {
  margin: -35px 0 0;
  min-height: 85px;
  padding: 12px 19px 18px;
  position: relative;
  z-index: 253;
  border: 6px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.vogue-2 > * {
  color: #ffffff;
  font-size: 2.604167rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  text-transform: uppercase;
}
.text-23 {
  margin: 15px auto 0;
  position: relative;
  width: 490px;
  z-index: 255;
  font-weight: 300;
  line-height: 1.2;
}
.stretching {
  left: 50%;
  position: absolute;
  top: 1081px;
  width: 500px;
  z-index: 0;
  margin-left: -1431.5px;
}
.play-copy-5 {
  left: 193.5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 268;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.free-icon-youtube-1077046-6 {
  display: block;
  margin: 0 auto;
}
.rectangle-9-holder-6 {
  margin: -36px 0 0;
  min-height: 85px;
  padding: 0 27px;
  position: relative;
  z-index: 263;
  border: 6px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.stretching-2 > * {
  color: #ffffff;
  font-size: 2.604167rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  line-height: 85px;
  text-transform: uppercase;
}
.text-24 {
  margin: 15px auto 0;
  position: relative;
  width: 490px;
  z-index: 265;
  font-weight: 300;
  line-height: 1.2;
}
.lady-dance {
  left: 50%;
  position: absolute;
  top: 1081px;
  width: 500px;
  z-index: 0;
  margin-left: -841.5px;
}
.play-copy-6 {
  left: 193.5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 278;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.free-icon-youtube-1077046-7 {
  display: block;
  margin: 0 auto;
}
.rectangle-9-holder-7 {
  margin: -36px 0 0;
  min-height: 85px;
  padding: 0 32px;
  position: relative;
  z-index: 273;
  border: 6px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.text-25 > * {
  color: #ffffff;
  font-size: 2.604167rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  line-height: 85px;
  text-transform: uppercase;
}
.text-26 {
  margin: 16px auto 0;
  position: relative;
  width: 490px;
  z-index: 275;
  font-weight: 300;
  line-height: 1.2;
}
.bachata {
  left: 50%;
  position: absolute;
  top: 1081px;
  width: 500px;
  z-index: 0;
  margin-left: -244.5px;
}
.play-copy-7 {
  left: 193.5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 288;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.free-icon-youtube-1077046-8 {
  display: block;
  margin: 0 auto;
}
.rectangle-9-holder-8 {
  margin: -36px 0 0;
  min-height: 85px;
  padding: 0 32px;
  position: relative;
  z-index: 283;
  border: 6px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.bachata-2 > * {
  color: #ffffff;
  font-size: 2.604167rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  line-height: 85px;
  text-transform: uppercase;
}
.text-27 {
  margin: 15px auto 0;
  position: relative;
  width: 490px;
  z-index: 285;
  font-weight: 300;
  line-height: 1.2;
}
.high-heels {
  left: 50%;
  position: absolute;
  top: 1081px;
  width: 500px;
  z-index: 0;
  margin-left: 345.5px;
}
.play-copy-8 {
  left: 194.5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 298;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.free-icon-youtube-1077046-9 {
  display: block;
  margin: 0 auto;
}
.rectangle-9-holder-9 {
  margin: -36px 0 0;
  min-height: 85px;
  padding: 0 26px;
  position: relative;
  z-index: 293;
  border: 6px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.text-28 > * {
  color: #ffffff;
  font-size: 2.604167rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  line-height: 85px;
  text-transform: uppercase;
}
.text-29 {
  margin: 15px auto 0;
  position: relative;
  width: 490px;
  z-index: 295;
  font-weight: 300;
  line-height: 1.2;
}
.parents-crew {
  left: 50%;
  position: absolute;
  top: 1081px;
  width: 500px;
  z-index: 0;
  margin-left: 931.5px;
}
.play-copy-9 {
  left: 192.5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 17px 0 19px;
  position: relative;
  width: 65px;
  z-index: 308;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.free-icon-youtube-1077046-10 {
  display: block;
  margin: 0 auto;
}
.rectangle-9-holder-10 {
  margin: -36px 0 0;
  min-height: 85px;
  padding: 0 24px;
  position: relative;
  z-index: 303;
  border: 6px solid #d31a71; /*stroke*/
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.text-30 > * {
  color: #ffffff;
  font-size: 2.604167rem; /* 41.67px / 16px = 2.604375rem */
  font-weight: 900;
  line-height: 85px;
  text-transform: uppercase;
}
.text-31 {
  margin: 15px auto 0;
  position: relative;
  width: 490px;
  z-index: 305;
  font-weight: 300;
  line-height: 1.2;
}
.price-area {
  /* height: 100vh; */
  /* margin: auto 0;
  overflow: hidden;
  position: relative; */
  /* width: 3556px; */

  left: 0;
  padding: 5% 0 0;
  /* position: absolute; */
  top: 30px;
  width: 100%; /* 3556px / 3556px = 100% */
  /* background: url(./../../../public/images/mqnddq3pl5a.jpg) no-repeat center 0; */
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.price_card {
  width:100%;
}

.price_title {
  color: #c4c4c4;
  font-size: 1.5rem; /* 27.74px / 16px = 1.73375rem */
  font-weight: 100;
}

.price_text_1 {
  font-size: 1rem; /* 28.67px / 16px = 1.791875rem */
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
}

.price_vip_title {
  font-size: 3rem; /* 66.67px / 16px = 4.166875rem */
}

.price_vip_1 {
  color: #dd71e0;
  font-size: 2.5rem; /* 66.67px / 16px = 4.166875rem */
  font-weight: 800;
}
.price_vip_2 {
  color: #eea20f;
  font-size: 2.5rem; /* 66.67px / 16px = 4.166875rem */
  font-weight: 800;
  line-height: 104.16666px;
}

.price_price {
  color:white;
  font-size: 0.9rem;
}

.price_comments {
  font-weight: 100;
  color: #a8c4c0;
  font-size: 12px;
}

.probnye-zanyatiya {
  left: 50%;
  /* position: absolute; */
  top: 504px;
  /* width: 771px; */
  /* margin-left: -1475px; */
}
.group_5 {
  float: left;
  /* height: 1055px; */
  position: absolute;
  /* width: 152px; */
}
.ellipse_5 {
  /* left: 50%;
  position: absolute;
  top: 175px;
  margin-left: -76px; */
  /* background: url(./../../../public/images/ellipse_5.png) no-repeat center 0; */
  width: 40px;
  /* position: absolute; */
}
.rectangle_15 {
  /* left: 50%;
  position: absolute;
  top: 0;
  margin-left: 36px;
  width: 20px; */

  right: 0%;
  /* position: absolute; */
  top: 0;
  margin-left: -10px;
  width: 25px;
}
.col-8 {
  float: left;
  margin: 119px 0 0 25px;
  position: relative;
  width: 424px;
}
.text-32 {
  color: #ffffff;
  font-size: 2rem; /* 43px / 16px = 2.6875rem */
  font-weight: 200;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}
.text-33 {
  margin: 38px auto 0;
  color: #ffffff;
  font-size: 1.5rem; /* 28.67px / 16px = 1.791875rem */
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
}
.rectangle-13-holder {
  margin: 26px auto 0;
  max-width: 391px;
  min-height: 75px;
  padding: 12px 0 12px;
  width: 80%;
  border: 5px solid #07e0f4; /*stroke*/
  -webkit-border-radius: 16.5px;
  -moz-border-radius: 16.5px;
  border-radius: 16.5px;
  color: #ffffff;
  font-size: 2rem; /* 35.63px / 16px = 2.226875rem */
  font-weight: 900;
  letter-spacing: 0.08em;
  text-align: center;
  text-transform: uppercase;
}
.group_5_copy {
  float: left;
  position: absolute;
}
.ellipse_5_2 {
  left: 50%;
  /* position: absolute; */
  width: 40px;
}
.rectangle_15_2 {
  left: 50%;
  /* position: absolute; */
  top: 0;
  width: 20px;
  margin-right: -10px;
}
.abonementiy-1-chas {
  left: 50%;
  /* position: absolute; */
  top: 504px;
  width: 770px;
  margin-left: -755px;
}
.group-5-2 {
  float: left;
  height: 1055px;
  position: relative;
  width: 151px;
}
.ellipse-5-3 {
  left: 50%;
  position: absolute;
  top: 175px;
  margin-left: -75.5px;
}
.rectangle-15-3 {
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: 35.5px;
}
.col-9 {
  float: left;
  margin: 118px 0 0 11px;
  position: relative;
  width: 421px;
}
.text-34 {
  margin: 0 32px 0 50px;
  color: #ffffff;
  font-weight: 200;
  line-height: 1.2;
  text-align: center;
}
.row-22 {
  margin: 76px 0 0;
  position: relative;
  width: 402px;
}
.text-35 {
  float: left;
  margin: 5px 0 0;
  width: 250px;
  color: #ffffff;
  font-size: 1.791827rem; /* 28.67px / 16px = 1.791875rem */
  font-weight: 100;
  line-height: 1.2;
  text-align: center;
}
.text-36 {
  float: right;
  width: 152px;
  color: #ffffff;
  font-size: 1.791667rem; /* 28.67px / 16px = 1.791875rem */
  font-weight: 100;
  line-height: 1.2;
  text-align: center;
}
.text-37 {
  margin: 7px 10px 0 41px;
  color: #ffffff;
  font-size: 1.189818rem; /* 19.04px / 16px = 1.19rem */
  font-weight: 100;
  line-height: 1.2;
  text-align: center;
}
.row-24 {
  left: 14.5px;
  margin: 54px auto 0;
  position: relative;
  width: 370px;
}
.text-38 {
  float: left;
  width: 174px;
  color: #ffffff;
  font-size: 1.791827rem; /* 28.67px / 16px = 1.791875rem */
  font-weight: 100;
  line-height: 1.2;
  text-align: center;
}
.text-39 {
  float: right;
  width: 173px;
  color: #ffffff;
  font-size: 1.791827rem; /* 28.67px / 16px = 1.791875rem */
  font-weight: 100;
  line-height: 1.2;
  text-align: center;
}
.text-40 {
  margin: 30px 10px 0 40px;
  color: #ffffff;
  font-size: 1.189818rem; /* 19.04px / 16px = 1.19rem */
  font-weight: 100;
  line-height: 1.2;
  text-align: center;
}
.rectangle-13-holder-2 {
  left: 15px;
  margin: 65px auto 0;
  min-height: 98px;
  padding: 21px 0 28px;
  position: relative;
  width: 391px;
  border: 5px solid #3ff0a1; /*stroke*/
  -webkit-border-radius: 26.5px;
  -moz-border-radius: 26.5px;
  border-radius: 26.5px;
  color: #ffffff;
  font-size: 2.226886rem; /* 35.63px / 16px = 2.226875rem */
  font-weight: 900;
  letter-spacing: 0.08em;
  text-align: center;
  text-transform: uppercase;
}
.group-5-copy-2 {
  float: left;
  height: 1056px;
  margin: 15px 0 0 31px;
  position: relative;
  width: 156px;
}
.ellipse-5-4 {
  left: 50%;
  position: absolute;
  top: 180px;
  margin-left: -51px;
}
.rectangle-15-4 {
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: -78px;
}
.abonementy-1-5-chasa {
  left: 50%;
  /* position: absolute; */
  top: 504px;
  width: 770px;
  margin-left: -26px;
}
.group-5-3 {
  float: left;
  height: 1055px;
  position: relative;
  width: 151px;
}
.ellipse-5-5 {
  left: 50%;
  position: absolute;
  top: 180px;
  margin-left: -75.5px;
}
.rectangle-15-5 {
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: 35.5px;
}
.col-11 {
  float: left;
  margin: 116px 0 0 23px;
  position: relative;
  width: 425px;
}
.text-41 {
  margin: 0 48px 0 39px;
  color: #ffffff;
  font-weight: 200;
  line-height: 1.2;
  text-align: center;
}
.text-42 {
  margin: 80px 0 0;
  color: #ffffff;
  font-size: 1.791827rem; /* 28.67px / 16px = 1.791875rem */
  font-weight: 100;
  line-height: 1.2;
  text-align: center;
}
.rectangle-13-holder-3 {
  margin: 95px auto 0;
  max-width: 391px;
  min-height: 98px;
  padding: 21px 0 28px;
  width: 391px;
  border: 5px solid #ee70d7; /*stroke*/
  -webkit-border-radius: 26.5px;
  -moz-border-radius: 26.5px;
  border-radius: 26.5px;
  color: #ffffff;
  font-size: 2.226886rem; /* 35.63px / 16px = 2.226875rem */
  font-weight: 900;
  letter-spacing: 0.08em;
  text-align: center;
  text-transform: uppercase;
}
.group-5-copy-3 {
  float: left;
  height: 1055px;
  margin: 16px 0 0 16px;
  position: relative;
  width: 155px;
}
.ellipse-5-6 {
  left: 50%;
  position: absolute;
  top: 174px;
  margin-left: -51.5px;
}
.rectangle-15-6 {
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: -77.5px;
}
.bezlimitnyi-abonement {
  left: 50%;
  /* position: absolute; */
  top: 504px;
  width: 771px;
  margin-left: 693px;
}
.bezlimitnyi-abonement > * {
  float: left;
  margin: 0 20.3px 0 0;
}
.bezlimitnyi-abonement > *:last-child {
  margin-right: 0 !important;
}
.group-5-4 {
  height: 1055px;
  position: relative;
  width: 151px;
}
.ellipse-5-7 {
  left: 50%;
  position: absolute;
  top: 180px;
  margin-left: -75.5px;
}
.rectangle-15-7 {
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: 35.5px;
}
.col-13 {
  position: relative;
  width: 423px;
  margin-top: 113px !important;
}
.text-43 {
  margin: 0 30px 0 23px;
  color: #ffffff;
  font-size: 2.687741rem; /* 43px / 16px = 2.6875rem */
  font-weight: 200;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}
.text-44 {
  margin: 31px 0 0;
  color: #ffffff;
  font-size: 1.791827rem; /* 28.67px / 16px = 1.791875rem */
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
}
.rectangle-13-holder-4 {
  margin: 43px auto 0;
  max-width: 391px;
  min-height: 98px;
  padding: 21px 0 28px;
  width: 391px;
  border: 5px solid #f58608; /*stroke*/
  -webkit-border-radius: 26.5px;
  -moz-border-radius: 26.5px;
  border-radius: 26.5px;
  color: #ffffff;
  font-size: 2.226886rem; /* 35.63px / 16px = 2.226875rem */
  font-weight: 900;
  letter-spacing: 0.08em;
  text-align: center;
  text-transform: uppercase;
}
.group-5-copy-4 {
  height: 1055px;
  position: relative;
  width: 156px;
  margin-top: 16px !important;
}
.ellipse-5-8 {
  left: 50%;
  position: absolute;
  top: 174px;
  margin-left: -52px;
}
.rectangle-15-8 {
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: -78px;
}
.ceny-2 {
  /* left: 50%; */
  /* position: absolute; */
  top: 239px;
  /* margin-left: -135px; */
  /* margin: 0 auto; */
  width: 20%;
}
.rectangle-4-3 {
  height: 18px;
  left: 50%;
  position: absolute;
  top: 332px;
  width: 491px;

  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
  margin-left: -240px;
}
.lights-2 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 3556px;
  opacity: 0.47;
  margin-left: -1778px;
}
.layer-6-2 {
  float: left;
}
.layer-6-copy-3 {
  float: right;
  margin: 104px 0 0;
}
.trenery {
  display: table;
  margin: -253px auto 0;
  position: relative;
}
.row-18 {
  position: relative;
  width: 2668px;
}
.row-18 > * {
  float: left;
  margin: 0 131px 0 0;
}
.row-18 > *:last-child {
  margin-right: 0 !important;
}
/* .bychkova-mariya {
  height: 703px;
  position: relative;
  width: 569px;
  margin-top: 272px !important;
} */
.bychkova-mariya {
  background: url(./../../../public/images/bychkova.png) no-repeat;
}

.coach {
  width: 80%;
  margin: auto;
  margin-top: -50px;
}

.coach_style {
    color: #c23d3d;
    font-size: 1rem; /* 47.29px / 16px = 2.955625rem */
    font-weight: 900;
    /* font-stretch: condensed; */
    line-height: 1.2;
    text-align: center;
    /* display: inline-block; */
    /* transform: scale(1.75, 1); */
    margin-top: 10px;
    /* width: 100%; */
}

.coach_name {
  color: #dfdfdf;
  font-size: 2rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
  margin: auto;
}

.coach_description {
  color: #999999;
  font-size: 1rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 500;
  line-height: 0.8;
  text-align: center;
  margin: auto;
}

.coach_icon_youtube {
  /* display: block; */
  /* background: url(./../../../public/images/free-icon-youtube-1077046_11.png) no-repeat; */
  text-align: end;
  cursor: pointer;
  text-align: center;
}

.coach_icon_youtube:hover{
  background: #202020;
}

.coach_icon_plus {
  background: url(./../../../public/images/rectangle_16.png) no-repeat;
  color: #ca1a6d;
  font-size: 3rem;
  font-weight: 600;
  /* letter-spacing: -0.3em; */
  text-align: center;
  top: -10px;
  position: relative;
  cursor: pointer;
}

.rectangle-16 {
  left: 50%;
  position: absolute;
  top: 540px;
  margin-left: 111.5px;
}
.col-15 {
  left: 50%;
  /* min-height: 598px;
  padding: 78px 25px 16px;
  position: absolute; */
  margin-bottom: 75px !important;
  /* width: 565px;
  z-index: 0; */
  background: url(./../../../public/images/layer_6_copy_3_2.png) no-repeat;
  /* margin-left: -284.5px; */
}
.ellipse-6-copy-2 {
  display: block;
  margin: 0 auto;
  position: relative;
  right: 3px;
  z-index: 416;
  background: url(./../../../public/images/ellipse_6_copy_2.png) no-repeat center 0;
}
.text-45 {
  display: block;
  margin: -8px auto 0;
  position: relative;
  z-index: 415;
}
.row-23 {
  margin: 17px auto 0;
  position: relative;
  width: 507px;
  z-index: 414;
}
.row-23 > * {
  float: left;
  margin: 0 62px 0 0;
}
.row-23 > *:last-child {
  margin-right: 0 !important;
}
.free-icon-youtube-1077046-11 {
  margin-top: 103px !important;
}
.text-46 {
  width: 282px;
  color: #ffffff;
  font-size: 2.955775rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
}
.text-47 {
  color: #ca1a6d;
  font-size: 4.449253rem; /* 71.19px / 16px = 4.449375rem */
  font-weight: 600;
  letter-spacing: -0.025em;
  text-align: center;
  margin-top: 81px !important;
}
.ellipse-6 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 448px;
  -webkit-border-radius: 224.21px/224.17px;
  -moz-border-radius: 224.21px/224.17px;
  border-radius: 224.21px/224.17px;
  -webkit-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29); /*drop shadow*/
  margin-left: -226.5px;
}
.col-32 {
  position: relative;
  width: 1268px;
}
.zagolovok {
  margin: 0 auto;
  position: relative;
  width: 491px;
}
.trenery-2 {
  display: block;
  margin: 0 auto;
  position: relative;
  right: 6px;
}
.rectangle-4-4 {
  height: 18px;
  margin: 35px 0 0;
  width: 491px;

  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.row-19 {
  margin: 161px 0 0;
  position: relative;
}
.deryagina {
  float: left;
  height: 703px;
  position: relative;
  width: 569px;
}
.rectangle-16-2 {
  left: 50%;
  position: absolute;
  top: 540px;
  margin-left: 112.5px;
}
.col-17 {
  left: 50%;
  min-height: 598px;
  padding: 78px 26px 16px;
  position: absolute;
  top: 101px;
  width: 566px;
  z-index: 0;
  background: url(./../../../public/images/layer_6_copy_3_3.png) no-repeat;
  margin-left: -284.5px;
}
.ellipse-6-copy-2-2 {
  display: block;
  margin: 0 auto;
  position: relative;
  right: 5px;
  z-index: 432;
}
.text-48 {
  display: block;
  margin: -8px auto 0;
  position: relative;
  z-index: 431;
}
.row-8 {
  left: 6px;
  margin: 20px auto 0;
  position: relative;
  width: 502px;
  z-index: 430;
}
.free-icon-youtube-1077046-copy {
  float: left;
  margin: 100px 0 0;
}
.text-49 {
  float: left;
  margin: 0 0 0 47px;
  width: 302px;
  color: #ffffff;
  font-size: 2.955775rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
}
.text-50 {
  float: left;
  margin: 78px 0 0 52px;
  color: #ca1a6d;
  font-size: 4.449253rem; /* 71.19px / 16px = 4.449375rem */
  font-weight: 600;
  letter-spacing: -0.025em;
  text-align: center;
}
.ellipse-6-2 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 448px;
  -webkit-border-radius: 224.21px/224.17px;
  -moz-border-radius: 224.21px/224.17px;
  border-radius: 224.21px/224.17px;
  -webkit-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29); /*drop shadow*/
  margin-left: -225.5px;
}
.parvfenov {
  float: right;
  height: 703px;
  position: relative;
  width: 570px;
}
.rectangle-16-3 {
  left: 50%;
  position: absolute;
  top: 540px;
  margin-left: 113px;
}
.col-19 {
  left: 50%;
  min-height: 598px;
  padding: 77px 26px 16px;
  position: absolute;
  top: 101px;
  width: 566px;
  z-index: 0;
  background: url(./../../../public/images/layer_6_copy_3_4.png) no-repeat;
  margin-left: -285px;
}
.ellipse-6-copy-2-3 {
  display: block;
  margin: 0 auto;
  position: relative;
  right: 5px;
  z-index: 446;
}
.text-51 {
  display: block;
  left: 3px;
  margin: -8px auto 0;
  position: relative;
  z-index: 445;
}
.row-9 {
  left: 6.5px;
  margin: 16px auto 0;
  position: relative;
  width: 501px;
  z-index: 444;
}
.free-icon-youtube-1077046-copy-2 {
  float: left;
  margin: 104px 0 0;
}
.text-52 {
  float: left;
  margin: 0 0 0 41px;
  width: 313px;
  color: #ffffff;
  font-size: 2.955775rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
}
.text-53 {
  float: left;
  margin: 82px 0 0 46px;
  color: #ca1a6d;
  font-size: 4.449253rem; /* 71.19px / 16px = 4.449375rem */
  font-weight: 600;
  letter-spacing: -0.025em;
  text-align: center;
}
.ellipse-6-3 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 448px;
  -webkit-border-radius: 224.21px/224.17px;
  -moz-border-radius: 224.21px/224.17px;
  border-radius: 224.21px/224.17px;
  -webkit-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29); /*drop shadow*/
  margin-left: -226px;
}
.sokolova {
  height: 703px;
  position: relative;
  width: 569px;
  margin-top: 272px !important;
}
.rectangle-16-4 {
  left: 50%;
  position: absolute;
  top: 540px;
  margin-left: 112.5px;
}
.col-21 {
  left: 50%;
  min-height: 598px;
  padding: 78px 25px 16px;
  position: absolute;
  top: 101px;
  width: 565px;
  z-index: 0;
  background: url(./../../../public/images/layer_6_copy_3_5.png) no-repeat;
  margin-left: -284.5px;
}
.ellipse-6-copy-2-4 {
  display: block;
  margin: 0 auto;
  position: relative;
  right: 4.5px;
  z-index: 460;
}
.contemporary-2 {
  display: block;
  margin: -8px auto 0;
  position: relative;
  z-index: 459;
}
.row-10 {
  left: 7px;
  margin: 19px auto 0;
  position: relative;
  width: 501px;
  z-index: 458;
}
.free-icon-youtube-1077046-copy-3 {
  float: left;
  margin: 101px 0 0;
}
.text-54 {
  float: left;
  margin: 0 0 0 39px;
  width: 315px;
  color: #ffffff;
  font-size: 2.955775rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
}
.text-55 {
  float: left;
  margin: 79px 0 0 46px;
  color: #ca1a6d;
  font-size: 4.449253rem; /* 71.19px / 16px = 4.449375rem */
  font-weight: 600;
  letter-spacing: -0.025em;
  text-align: center;
}
.ellipse-6-4 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 448px;
  -webkit-border-radius: 224.21px/224.17px;
  -moz-border-radius: 224.21px/224.17px;
  border-radius: 224.21px/224.17px;
  -webkit-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29); /*drop shadow*/
  margin-left: -225.5px;
}

.row {
  padding: 0 !important;
  margin: 0 !important;
}

/* .row {
  margin: 96px 0 0;
  position: relative;
  width: 2668px;
}
.row > * {
  float: left;
  margin: 0 130.13px 0 0;
}
.row > *:last-child {
  margin-right: 0 !important;
} */
.kurmeleva {
  height: 703px;
  position: relative;
  width: 569px;
}
.rectangle-16-5 {
  left: 50%;
  position: absolute;
  top: 540px;
  margin-left: 111.5px;
}
.col-23 {
  left: 50%;
  min-height: 598px;
  padding: 78px 25px 16px;
  position: absolute;
  top: 101px;
  width: 565px;
  z-index: 0;
  background: url(./../../../public/images/layer_6_copy_3_2.png) no-repeat;
  margin-left: -284.5px;
}
.ellipse-6-copy-2-5 {
  display: block;
  margin: 0 auto;
  position: relative;
  right: 3px;
  z-index: 474;
}
.text-56 {
  display: block;
  left: 4.5px;
  margin: -8px auto 0;
  position: relative;
  z-index: 473;
}
.row-11 {
  margin: 18px auto 0;
  position: relative;
  width: 507px;
  z-index: 472;
}
.row-11 > * {
  float: left;
  margin: 0 31.3px 0 0;
}
.row-11 > *:last-child {
  margin-right: 0 !important;
}
.free-icon-youtube-1077046-copy-4 {
  margin-top: 96px !important;
}
.text-57 {
  width: 343px;
  color: #ffffff;
  font-size: 2.955775rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
}
.text-58 {
  color: #ca1a6d;
  font-size: 4.449253rem; /* 71.19px / 16px = 4.449375rem */
  font-weight: 600;
  letter-spacing: -0.025em;
  text-align: center;
  margin-top: 78px !important;
}
.ellipse-6-5 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 448px;
  -webkit-border-radius: 224.21px/224.17px;
  -moz-border-radius: 224.21px/224.17px;
  border-radius: 224.21px/224.17px;
  -webkit-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29); /*drop shadow*/
  margin-left: -226.5px;
}
.zabirova {
  height: 703px;
  position: relative;
  width: 569px;
}
.rectangle-16-6 {
  left: 50%;
  position: absolute;
  top: 540px;
  margin-left: 112.5px;
}
.col-25 {
  left: 50%;
  min-height: 598px;
  padding: 78px 26px 16px;
  position: absolute;
  top: 101px;
  width: 566px;
  z-index: 0;
  background: url(./../../../public/images/layer_6_copy_3_3.png) no-repeat;
  margin-left: -284.5px;
}
.ellipse-6-copy-2-6 {
  display: block;
  margin: 0 auto;
  position: relative;
  right: 5px;
  z-index: 489;
}
.dancehall-3 {
  display: block;
  margin: -8px auto 0;
  position: relative;
  z-index: 488;
}
.row-12 {
  margin: 19px auto 0;
  position: relative;
  width: 506px;
  z-index: 487;
}
.free-icon-youtube-1077046-copy-5 {
  float: left;
  margin: 97px 0 0;
}
.text-59 {
  float: left;
  margin: 0 0 0 47px;
  width: 308px;
  color: #ffffff;
  font-size: 2.955775rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
}
.text-60 {
  float: left;
  margin: 79px 0 0 50px;
  color: #ca1a6d;
  font-size: 4.449253rem; /* 71.19px / 16px = 4.449375rem */
  font-weight: 600;
  letter-spacing: -0.025em;
  text-align: center;
}
.ellipse-6-6 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 448px;
  -webkit-border-radius: 224.21px/224.17px;
  -moz-border-radius: 224.21px/224.17px;
  border-radius: 224.21px/224.17px;
  -webkit-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29); /*drop shadow*/
  margin-left: -225.5px;
}
.groza {
  height: 703px;
  position: relative;
  width: 570px;
}
.rectangle-16-7 {
  left: 50%;
  position: absolute;
  top: 540px;
  margin-left: 113px;
}
.col-27 {
  left: 50%;
  min-height: 598px;
  padding: 77px 26px 16px;
  position: absolute;
  top: 101px;
  width: 566px;
  z-index: 0;
  background: url(./../../../public/images/layer_6_copy_3_4.png) no-repeat;
  margin-left: -285px;
}
.ellipse-6-copy-2-7 {
  display: block;
  margin: 0 auto;
  position: relative;
  right: 5px;
  z-index: 503;
}
.vogue-3 {
  display: block;
  left: 3px;
  margin: -8px auto 0;
  position: relative;
  z-index: 502;
}
.row-13 {
  margin: 19px auto 0;
  position: relative;
  width: 505px;
  z-index: 501;
}
.row-13 > * {
  float: left;
  margin: 0 110px 0 0;
}
.row-13 > *:last-child {
  margin-right: 0 !important;
}
.free-icon-youtube-1077046-copy-6 {
  margin-top: 97px !important;
}
.text-61 {
  width: 184px;
  color: #ffffff;
  font-size: 2.955775rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
}
.text-62 {
  color: #ca1a6d;
  font-size: 4.449253rem; /* 71.19px / 16px = 4.449375rem */
  font-weight: 600;
  letter-spacing: -0.025em;
  text-align: center;
  margin-top: 79px !important;
}
.ellipse-6-7 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 448px;
  -webkit-border-radius: 224.21px/224.17px;
  -moz-border-radius: 224.21px/224.17px;
  border-radius: 224.21px/224.17px;
  -webkit-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29); /*drop shadow*/
  margin-left: -226px;
}
.malkova {
  height: 703px;
  position: relative;
  width: 569px;
}
.rectangle-16-8 {
  left: 50%;
  position: absolute;
  top: 540px;
  margin-left: 112.5px;
}
.col-29 {
  left: 50%;
  min-height: 598px;
  padding: 78px 25px 16px;
  position: absolute;
  top: 101px;
  width: 565px;
  z-index: 0;
  background: url(./../../../public/images/layer_6_copy_3_5.png) no-repeat;
  margin-left: -284.5px;
}
.ellipse-6-copy-2-8 {
  display: block;
  margin: 0 auto;
  position: relative;
  right: 4.5px;
  z-index: 517;
}
.text-63 {
  display: block;
  left: 3.5px;
  margin: -8px auto 0;
  position: relative;
  z-index: 516;
}
.row-14 {
  left: 7px;
  margin: 14px auto 0;
  position: relative;
  width: 501px;
  z-index: 515;
}
.free-icon-youtube-1077046-copy-7 {
  float: left;
  margin: 100px 0 0;
}
.text-64 {
  float: left;
  margin: 0 0 0 55px;
  width: 285px;
  color: #ffffff;
  font-size: 2.955775rem; /* 47.29px / 16px = 2.955625rem */
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
}
.text-65 {
  float: left;
  margin: 82px 0 0 60px;
  color: #ca1a6d;
  font-size: 4.449253rem; /* 71.19px / 16px = 4.449375rem */
  font-weight: 600;
  letter-spacing: -0.025em;
  text-align: center;
}
.ellipse-6-8 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 448px;
  -webkit-border-radius: 224.21px/224.17px;
  -moz-border-radius: 224.21px/224.17px;
  border-radius: 224.21px/224.17px;
  -webkit-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29);
  box-shadow: -1.144854px 5.889763px 8px 0 rgba(0, 0, 0, 0.29); /*drop shadow*/
  margin-left: -225.5px;
}
.l-unconstrained-2 {
  margin: 364px 0 0;
  overflow: hidden;
  position: relative;
  justify-content: center !important;
}
.l-constrained-4 {
  margin: 0 auto;
  position: relative;
  width: 3556px;
}
.galereya {
  overflow: hidden;
  position: relative;
}
.galereya-2 {
  display: table;
  margin: 0 auto;
  position: relative;
}
.galereya-3 {
  display: block;
  margin: 0 auto;
}
.rectangle-4-copy {
  height: 18px;
  margin: 34px auto 0;
  width: 491px;

  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.wrapper-8 {
  height: 3389px;
  margin: 168px auto 0;
  position: relative;
  width: 3556px;
}
.lights-3 {
  height: 3163px;
  left: 50%;
  position: absolute;
  top: 226px;
  width: 3556px;
  margin-left: -1778px;
}
.layer-6-copy-4 {
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: -1778px;
}
.layer-6-copy-5 {
  left: 50%;
  position: absolute;
  top: 138px;
  margin-left: -225px;
}
.group-6-copy {
  left: 50%;
  position: absolute;
  top: 1207px;
  width: 2848px;
  margin-left: -1429px;
}
.col-4 {
  float: left;
  margin: 2px 0 0;
  position: relative;
  width: 709px;
}
.rectangle-17-copy-4 {
  height: 717px;
  width: 709px;

  background: url(./../../../public/images/rectangle_17_copy_4_2.png) no-repeat;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.rectangle-17-copy-2 {
  height: 717px;
  width: 709px;

  background: url(./../../../public/images/rectangle_17_copy_2_2.png) no-repeat;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.wrapper-13 {
  float: left;
  height: 1436px;
  position: relative;
  width: 2139px;
}
.rectangle-17 {
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: -361.5px;
}
.rectangle-17-copy {
  height: 717px;
  left: 50%;
  position: absolute;
  top: 719px;
  width: 709px;

  background: url(./../../../public/images/rectangle_17_copy_5.png) no-repeat;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
  margin-left: -1069.5px;
}
.rectangle-17-copy-3 {
  height: 717px;
  left: 50%;
  position: absolute;
  top: 2px;
  width: 709px;

  background: url(./../../../public/images/rectangle_17_copy_3_2.png) no-repeat;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
  margin-left: -1069.5px;
}
.group-6 {
  left: 50%;
  position: absolute;
  top: 0;
  width: 2848px;
  margin-left: -1428px;
}
.rectangle-17-2 {
  float: left;
  margin: 0 -1px 0 0;
  position: relative;
}
.col-33 {
  float: left;
  position: relative;
  width: 1418px;
}
.row-20 {
  position: relative;
}
.rectangle-17-copy-5 {
  float: left;
  height: 717px;
  width: 709px;

  background: url(./../../../public/images/rectangle_17_copy.jpg) no-repeat;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.rectangle-17-copy-2-2 {
  float: left;
  height: 717px;
  width: 709px;

  background: url(./../../../public/images/rectangle_17_copy_2.jpg) no-repeat;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.row-21 {
  position: relative;
}
.rectangle-17-copy-3-2 {
  float: left;
  height: 717px;
  width: 709px;

  background: url(./../../../public/images/rectangle_17_copy_3.jpg) no-repeat;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.rectangle-17-copy-4-2 {
  float: left;
  height: 717px;
  width: 709px;

  background: url(./../../../public/images/rectangle_17_copy_4.jpg) no-repeat;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
/* .otzyvy {
  height: 1920px;
  left: 316.5px;
  margin: -762px auto 0;
  position: relative;
  width: 2879px;
  background: url(./../../../public/images/vzroslyye.jpg) no-repeat;
} */
.otzyvy {
  left: 0;
  padding: 50px 20px 50px 20px;
  /* position: absolute; */
  top: 30px;
  width: 100%; /* 3556px / 3556px = 100% */
  background: url(./../../../public/images/otzyvy-background.jpg) no-repeat center 0;
  /* background: url(./../../../public/images/mqnddq3pl5a.jpg) no-repeat center 0; */
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;

}

.dots {
  left: 50%;
  position: absolute;
  top: 1100px;
  width: 114px;
  margin-left: -370.5px;
}
.dots > * {
  float: left;
  margin: 0 14px 0 0;
}
.dots > *:last-child {
  margin-right: 0 !important;
}
.ellipse-505 {
  height: 18px;
  width: 18px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.ellipse-505-copy {
  height: 18px;
  width: 18px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.ellipse-505-copy-2 {
  height: 18px;
  width: 18px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.ellipse-505-copy-3 {
  height: 18px;
  width: 18px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.otzyvy-2 {
  left: 50%;
  position: absolute;
  top: 505px;
  background: url(./../../../public/images/otzyvy-background.jpg) no-repeat;
  /* margin-left: -525.5px; */
}
.text_otzyvy {
  left: 13px;
  top: 15%;
  color:  hwb(0 43% 56%);
  font-size: 1rem; /* 33.33px / 16px = 2.083125rem */
  line-height: 1.2;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}
.text_otzyvy_details {
  left: 13px;
  top: 15%;
  color: #cacaca;
  font-size: 1rem; /* 33.33px / 16px = 2.083125rem */
  line-height: 1.2;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}
.text-66 {
  left: 50%;
  position: absolute;
  top: 688px;
  width: 1509px;
  color: #767676;
  font-weight: 300;
  line-height: 1.2;
  text-align: center;
  /* margin-left: -1068.5px; */
}
.group-7 {
  height: 107px;
  left: 50%;
  position: absolute;
  top: 895px;
  width: 318px;
  /* margin-left: -473.5px; */
}
.rectangle-18 {
  height: 7px;
  left: 50%;
  position: absolute;
  top: 47px;
  width: 318px;
  border: 1px solid #0d0f05; /*stroke*/
  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
  /* margin-left: -159px; */
}
.text-67 {
  left: 50%;
  position: absolute;
  top: 0;
  color: #ffffff;
  font-size: 5.539803rem; /* 88.64px / 16px = 5.54rem */
  font-weight: 900;
  line-height: 107px;
  margin-left: -39px;
}
.rectangle-4-copy-2 {
  height: 18px;
  left: 50%;
  position: absolute;
  top: 600px;
  width: 491px;

  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
  margin-left: -558.5px;
}
.layer-6-copy-6 {
  left: 50%;
  position: absolute;
  top: 459px;
  margin-left: -1276.5px;
}

.about-us {
  left: 20%;
  padding: 50px 20px 50px 20px;
  /* position: absolute; */
  top: 30px;
  width: 100%; /* 3556px / 3556px = 100% */
  background: url(./../../../public/images/otzyvy-background.jpg) no-repeat center 0;
  /* background: url(./../../../public/images/mqnddq3pl5a.jpg) no-repeat center 0; */
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
}

.kontakty {
  margin: -576px 0 0;
  position: relative;
}
.footer {
  height: 766px;
  position: relative;
  width: 100%; /* 3556px / 3556px = 100% */
}
.col-5 {
  left: 0;
  min-height: 766px;
  padding: 123px 0 0;
  position: absolute;
  top: 0;
  width: 100%; /* 3556px / 3556px = 100% */
  background: #000000;
  background-position: 0 0;
}
.logo_dm_white {
  display: block;
  /* margin: 0 auto; */
  position: relative;
  /* background: url(./../../../public/images/logo_dm_white_2.png) no-repeat center 0; */
}
.rectangle-550 {
  display: block;
  height: 10px;
  margin: 25px auto 0;
  position: relative;
  right: 678.5px;
  width: 1111px;

  background: #d31a71;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; /*Will not allow bg color to leak outside borders*/
}
.row-26 {
  margin: 32px auto 0;
  padding: 0 541px;
  position: relative;
  width: 3556px;
}
.col-41 {
  float: left;
  margin: 0 109px 0 0;
  position: relative;
  width: 510px;
}
.text-68 {
  left: 4px;
  margin: 0 auto;
  position: relative;
  width: 502px;
  color: #7b7b7b;
  font-size: 1.562501rem; /* 25px / 16px = 1.5625rem */
  font-weight: 300;
  line-height: 33.29325px;
}
.social_media_icons {
  position: relative;
}
.icon {
  position: relative;
}
.icon > * {
  /* float: left; */
  margin: 5px;
}
.icon > *:last-child {
  margin-right: 0 !important;
}
/* .instagram {
  margin-top: 1px !important;
}
.whatsapp {
  margin-top: 1px !important;
} */
.col-42 {
  float: left;
  margin: 7px 0 0;
  position: relative;
  width: 524px;
}
.row-15 {
  position: relative;
}
.phone_call {
  /* float: left; */
  margin: 0 5px 0 0;
}
.text-69 {
  margin: 3px 0 0;
  color: #7b7b7b;
  font-size: 1.734023rem; /* 27.74px / 16px = 1.73375rem */
}
.row-16 {
  margin: 27px 0 0;
  position: relative;
}
.empty_email {
  /* float: left; */
  margin: 4px 5px 0 0;
}
.text-70 {
  color: #7b7b7b;
  font-size: 1.734023rem; /* 27.74px / 16px = 1.73375rem */
}
.row-17 {
  margin: 30px 0 0 5px;
  position: relative;
}
.icon_location {
  float: left;
  margin: 0 5px 0 0;
}
.text-71 {
  float: left;
  color: #7b7b7b;
  font-size: 1.734023rem; /* 27.74px / 16px = 1.73375rem */
  line-height: 33.29325px;
}
.copyright {
  margin: 131px 0 0;
  padding: 40px 0 41px;
  position: relative;
  background: #333333;
  background-position: 0 0;
}
.l-constrained-3 {
  margin: 0 auto;
  position: relative;
  width: 3556px;
}
.text-72 {
  margin: 0 549px;
  color: #999999;
  font-size: 1.734023rem; /* 27.74px / 16px = 1.73375rem */
  font-weight: 100;
}

.contacts {
  margin-bottom: 10px;
  margin: auto;
}

.contacts_text{
  color: #4d4c4c;
  font-size: 1rem;
  /* line-height: 0.5; */
  /* text-align: left; */
}

.map {
  width:100%;
  height:100%;
  object-fit:cover;
  margin: auto;
}

.fw900 {
  font-weight: 900;
}
.text-style-9 {
  color: #ffffff;
  text-decoration: underline;
}
.colorffffff {
  color: #ffffff;
}
.text-style-3 {
  font-size: 2.083333rem; /* 33.33px / 16px = 2.083125rem */
  font-weight: 900;
}
.fw700 {
  font-weight: bold;
}
.text-style-7 {
  font-size: 4.166667rem; /* 66.67px / 16px = 4.166875rem */
  font-weight: 900;
}
.text-style-2 {
  line-height: 40px;
}
.fw100 {
  font-weight: 100;
  color:#a8c4c0;
}
.text-style-8 {
  line-height: 35px;
}
.text-style {
  line-height: 45px;
}
.text-style-6 {
  font-size: 4.166667rem; /* 66.67px / 16px = 4.166875rem */
  font-weight: 100;
}
.fw600 {
  font-weight: 600;
}
.text-style-5 {
  font-size: 2.687741rem; /* 43px / 16px = 2.6875rem */
  text-transform: uppercase;
}
.text-style-4 {
  line-height: 34px;
}

.container.custom_container {
  margin: 0px;
  padding: 0px;
}

.height-30 {
  height: 30%;
}

.home_main_image {
  left: 10%;
  position: absolute;
  top: 10%;
  width: 35%;
  min-width: 250px;
}

.home_main_image_mobile {
  position: relative;
  top: 10%;
  width: 100%;
  min-width: 250px;
}

.underline_margin_check {
  display: inline-block;
  position: relative;
  max-width: 30%;
}

.underline_margin_check:after {
  position: absolute;
  content: '';
  border-bottom: 6px solid #d31a71;
  width: 60%;
  transform: translateX(-50%);
  bottom: -15px;
  left: 50%;
}

.halls {
  /* width: 70%; */
}

.halls_title {
  color:  #d31a71;
  font-size: 3rem;
  font-weight: 900;
  /* -webkit-text-fill-color: rgb(0, 0, 0); 
  -webkit-text-stroke: 0.1rem  #d31a71; */
}
.halls_size {
  left: 50%;
  top: 574px;
  width: 1332px;
  font-size: 2.5rem; /* 62.5px / 16px = 3.90625rem */
  font-weight: 100;
  margin-left: 110px;
}

.halls_price {
  color: #d0d0d0;
  font-size: 1.8rem; /* 62.5px / 16px = 3.90625rem */
  font-weight: 100;
  margin-left: 30px;
}


.halls_size_details {
  color: #d0d0d0;
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
  text-transform: uppercase;
}

.halls_size_details_17 {
  line-height: 75px;
}

.halls_size_details_18 {
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;
  color: white;
}

/* .halls_size_details_19 {
  font-weight: 800;
  line-height: 62.5px;
  text-transform: uppercase;
}
 */
.halls_size_details_20 {
  color: #d0d0d0;
}

.hall_images {
  border-radius: 50px;
  padding: 30px;
}

.hall_description {
  padding-top: 20px;
}

.hall_branch_button {
    width: auto;
    padding: 0 15px 0 15px;
    margin: 0 10px 0 10px;
    color:#ffffff;
    border: 3px solid #404040;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    font-size: 1.2rem;
    font-weight: 800;
    letter-spacing: 0.06em;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    line-height: 45px;
}

.hall_branch_active {
  background-color: #d31a71;
}


@media (min-width: 768px)
{
  .layer-6-copy-2 {
    height:100vh;
  }

  .bg-photo{
    height:100vh;
  }

  .ellipse {
    height: 100vh;
  }
}

@media (max-width: 768px)
{
  .underline_margin_check
  {
    max-width: 100%;
  }

  .ellipse
  {
    max-width: 30%;
    position: absolute;
    top: 0;
    height: 100vh;
  }

  .main-image
  {
    left: 10%;
      position: absolute;
      top: 10%;
      width: 80%;
      min-width: 250px;
  }

  .tancui
  {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: unset;
  }

  .kruche
  {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
  }

  .zapisatsya
  {
    left: 0;
    right: 0;
    margin: 5% auto;
    width: fit-content;
  }
}

.mt_50p {
  margin-top: 50px !important;
}

.mt_70p {
  margin-top: 70px !important;
}


.mt_15p {
  margin-top: 15% !important;
}
