.modal {
  width: 100%;
  /* top:30%; */
}

.modal_header {
  background-color: rgb(255, 255, 255);
}

.modal_body {
    padding: unset;
    width: 100%;
}

.player {
  height:"90vh";
}