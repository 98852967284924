.home-background {
  position: relative;
  background: #000000 center 0;
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.row {
  margin-right: 0px !important;
  padding: 10px 0 10px 10px !important;
  /* padding: 2px !important;
  margin: 0 !important; */
}

.mb_70px {
  margin-bottom: 70px !important;
}

.bm-burger-button {
  right: 36px;
  left: unset !important;
}
