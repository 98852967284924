.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.accordion {
    all: unset !important;
    --bs-accordion-color:  unset !important;
    --bs-accordion-bg:  unset !important;
    --bs-accordion-transition:  unset !important;
    --bs-accordion-border-color: unset !important;
    --bs-accordion-border-width: unset !important;
    --bs-accordion-border-radius: unset !important;
    --bs-accordion-inner-border-radius: unset !important;
    --bs-accordion-btn-padding-x: unset !important;
    --bs-accordion-btn-padding-y: unset !important;
    --bs-accordion-btn-color: unset !important;
    --bs-accordion-btn-bg: unset !important;
    --bs-accordion-btn-icon: unset !important;
    --bs-accordion-btn-icon-width: unset !important;
    --bs-accordion-btn-icon-transform: unset !important;
    --bs-accordion-btn-icon-transition: unset !important;
    --bs-accordion-btn-active-icon: unset !important;
    --bs-accordion-btn-focus-border-color: unset !important;
    --bs-accordion-btn-focus-box-shadow: unset !important;
    --bs-accordion-body-padding-x: unset !important;
    --bs-accordion-body-padding-y: unset !important;
    --bs-accordion-active-color: unset !important;
    --bs-accordion-active-bg: unset !important;
}

.justify-content-left {
  text-align: left;
}
